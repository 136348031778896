import React from 'react';
import './Sidebar.scss';
import { useUserAuth } from '../../providers/UserAuthContext';
import { Link } from 'react-router-dom';
import { BsEye, BsBarChart,  BsCloudUpload, BsClipboardCheck,  BsBell, BsGear, BsClipboardData,BsCalendarWeek, BsShop, BsPerson, BsPersonPlus, BsHeadset, BsFolder, BsPencil, BsSearch, BsBarChartLine } from "react-icons/bs";
import { RiVirusLine } from "react-icons/ri";
import Button from '../../components/Button/Button';


function Sidebar() {

    const general = [
        {
            key: '1',
            icon: <BsEye/>,
            title: 'Dashboard',
            path: '/dashboard',
            className: 'sidebar__item'
        },
        {
            key: '2',
            icon: <BsBell/>,
            title: 'Notificaciones',
            path: '/notificaciones',
            className: 'sidebar__item'
        },
        {
            key: '3',
            icon: <BsBarChart/>,
            title: 'Analytics',
            path: '/vieci-analytics',
            className: 'sidebar__item'
        },
        {
            key: '5',
            icon: <BsClipboardCheck/>,
            title: 'Checklists',
            path: '/vieci-checklists',
            className: 'sidebar__item'
        },
        {
            key: '5',
            icon: <BsFolder/>,
            title: 'Documentos',
            path: '/documentos-informes',
            className: 'sidebar__item'
        },
        {
            key: '5',
            icon: <BsGear/>,
            title: 'Configuraciones',
            path: '/centro-de-ayuda',
            className: 'sidebar__item'
        },

    ]

    // const analisis = [
    //     {
    //         key: '1',
    //         icon: <BsCloudUpload/>,
    //         title: 'Cargar archivos',
    //         path: '/upload',
    //         className: 'sidebar__item'
    //     },
    //     {
    //         key: '1',
    //         icon: <BsBarChartLine/>,
    //         title: 'Epidemiología',
    //         path: '/epidemiologia',
    //         className: 'sidebar__item'
    //     }
        //  {
        //      key: '2',
        //      icon: <BsSearch/>,
        //      title: 'Análisis IAAS',
        //      path: '/analisis',
        //      className: 'sidebar__item'
        //  },
        //  {
        //      key: '4',
        //      icon: <BsShop/>,
        //      title: 'Servicios',
        //      path: '/servicios',
        //      className: 'sidebar__item'
        //  },
        // {
        //      key: '5',
        //      icon: <BsClipboardData/>,
        //      title: 'Antibioticos',
        //      path: '/antibioticos-costos',
        //      className: 'sidebar__item'
        // },
        //  {
        //       key: '6',
        //       icon: <BsClipboardCheck/>,
        //       title: 'Estrategia ...',
        //       path: '/listas-de-chequeo',
        //       className: 'sidebar__item'
        //  }
    // ];

    // const recoleccion = [
    //     {
    //         key: '1',
    //         icon: <RiVirusLine/>,
    //         title: 'Microorganismos',
    //         path: '/dashboard',
    //         className: 'sidebar__item'
    //     },
        //  {
        //      key: '2',
        //      icon: <BsPaperclip/>,
        //      title: 'Antibióticos',
        //      path: '/dashboard',
        //      className: 'sidebar__item'
        //  },
        //  {
        //      key: '3',
        //      icon: <BsShop/>,
        //      title: 'Servicios',
        //      path: '/dashboard',
        //      className: 'sidebar__item'
        //  },
    //     {
    //         key: '4',
    //         icon: <BsFileMedical/>,
    //         title: 'Oportunidades de mejora',
    //         path: '/dashboard',
    //         className: 'sidebar__item'
    //     },
    // ]
    // const administracion = [
    //     {
    //         key: '1',
    //         icon: <BsPerson/>,
    //         title: 'Perfil',
    //         path: '/perfil',
    //         className: 'sidebar__item'
    //     },
    //     {
    //         key: '2',
    //         icon: <BsPersonPlus/>,
    //         title: 'Crear usuario',
    //         path: '/crear-usuario',
    //         className: 'sidebar__item'
    //     },
    //     {
    //         key: '3',
    //         icon: <BsHeadset/>,
    //         title: 'Centro de ayuda',
    //         path: '/centro-de-ayuda',
    //         className: 'sidebar__item'
    //     }, 
    // ];

    const { user, logOut } = useUserAuth();

    const handleLogOut = async () => {
        try {
            await logOut();
        } catch(err) {
            console.log(err.message);
        }
    }


 
    return (
        <>
        <div className='sidebar'>
            <header>
                <h2>Vieci <span className='sidebar__dot'>.</span></h2>
                <div className="sidebar__container">
                    <h5>General</h5>
                    <ul>
                        {
                            general.map( item => (
                            <li key={item.key} className={item.className}>
                                    <Link to={item.path}>
                                        {item.icon}
                                        <span>{item.title}</span>
                                    </Link>
                            </li>
                            )
                                
                            )
                        }  
                    </ul>
                    {/* <h5>Recolección de datos</h5> */}
                    {/* <ul>
                        {
                            recoleccion.map( item => (
                                <li key={item.key} className={item.className}>
                                        <Link to={item.path}>
                                            {item.icon}
                                            <span>{item.title}</span>
            
                                        </Link>
                                </li>
                                )
                                    
                                )    
                        }
                    </ul> */}
                    <Button
                        title='Cerra sesión'
                         className='sidebar__btn'
                         onClick={handleLogOut}
                    />
                </div>
            </header>
        </div>
        </>
    )
}

export default Sidebar
