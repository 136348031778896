import React from 'react';
import './Perfil.scss';
import Sidebar from '../../components/Sidebar/Sidebar';


function Perfil() {


    return (
        <>
        <Sidebar/>
        <div className='perfil'>
            <h2>Perfil<span>.</span></h2>  
            <div className="perfil__container">
                <p>Institución</p>
                <input placeholder='Clínica Materno Infantil - Eu Salud' readOnly/>
                <p>Ciudad</p>
                <input placeholder='Bogotá D.C.' readOnly/>
                <p>Dirección</p>
                <input placeholder=' Dg. 54 # 16A-16,' readOnly/>
                <p>Teléfono</p>
                <input placeholder='(1) 4320872' readOnly/>   
            </div>
        </div>
        </>
    )
}

export default Perfil
