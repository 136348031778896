import React from 'react';
import './Dashboard.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import InfoCard from '../../components/InfoCard/InfoCard';
import GeneralFilter from '../../components/GeneralFilter/GeneralFilter';
import Button from '../../components/Button/Button';
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';

function Semestre() {

    const data = [
        {
            key: '1',
            title: '113',
            subtitle: 'Total de cultivos positivos'
        },
        {
            key: '2',
            title: 'E. coli',
            subtitle: 'Microorgismo mas prevalente'
        },
        {
            key: '3',
            title: 'Urocultivo',
            subtitle: 'Tipo de cultivo mas tomado'
        },
        {
            key: '4',
            title: 'UCI neonatal',
            subtitle: 'Servicio con más cultivos positivos'
        },
    ]
    return (
        <>
        <Sidebar/>
        <div className='dashboard'>
            <h2>Análisis de IAAS<span>.</span></h2>
            <br />
            <GeneralFilter/>
            <br />
            <h4>Último semestre</h4>
            <div className="dashboard__info">
                {
                    data.map(item => 
                        <InfoCard
                            key={item.key}
                            title={item.title}
                            subtitle={item.subtitle}
                        />
                        )
                }
              
            </div>
            <div className="dashboard__graph-container">
                <div className='dashboard__graph-title'>
                <h5>Tasa de infección</h5>
                <div className="dashboard__graph">
                <Line
                        data={{
                            labels:['Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto'],
                            datasets: [{
                                label: 'Tasa global de infección',
                                data: [0.69, 0.16],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                </div>
                </div>
                <div className="dashboard__graph-title">
                <h5>Microorganismos mas prevalentes</h5>
                    <div className="dashboard__graph">
                    <Pie
                        data={{
                            labels:[ 'E. coli', 'S. epidermis,', 'S. hominis', ' K. pneumoniae','E. faecalis', ],
                            datasets: [{
                                label: '',
                                data: [36,22,7,6,5],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                    </div>
                </div>
            </div>
            <div className="dashboard__card-container">
                <div className='dashboard__card-title'>
                <h5>Tipo de cultivo</h5>
                <div className="dashboard__card">
                <Bar
                            data={{
                                labels:['Coprocultivo', 'Hemocultivo', 'Secreción', 'Urocultivo'],
                                datasets: [{
                                    label: 'Antibióticos usados en el primer semestre',
                                    data: [3, 39, 8, 63],
                                    backgroundColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                    borderColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"30%"}
                            options={{ maintainAspectRatio: false }}
                            height={600}
                        />
                </div>
                </div>
                <div className='dashboard__card-title'>
                    <h5>Total de cultivos tomados</h5>
                <div className="dashboard__card">
                <Line
                         data={{
                            labels:['Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto'],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [16, 20, 21, 20, 10, 26],
                                backgroundColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                borderColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
                <div className='dashboard__card-title'>
                    <h5>Cultivos por servicio</h5>
                <div className="dashboard__card">
                <Bar
                         data={{
                            labels:['Hospitalización', 'UCI Neonatal', 'UCI Adultos', 'Cirugia', 'C. externa', 'TMP'],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [27, 23, 8, 2, 2, 1],
                                backgroundColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                     
                                    ],
                                borderColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                      
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
            
            </div>
        </div>
        </>
    )
}

export default Semestre;
