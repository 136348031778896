import React from 'react';
import './Analytics.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { BiBarChart } from "react-icons/bi";
import InfoCard from '../../components/InfoCard/InfoCard';

function Soporte() {

    return (
        <>
        <Sidebar/>
        <div className='soporte'>
            <h2>Vieci analytics<span>.</span></h2>
            <div className="soporte__container">
                <Link to='/vieci-analytic-iaas'>
                    <InfoCard
                        title={<BiBarChart/>}
                        subtitle='Análisis y monitoreo de IAAS'
                    /> 
                </Link>
            </div>
        </div>
        </>
    )
}

export default Soporte
