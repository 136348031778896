import React from 'react';
import './Checklists.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { BsClipboardCheck} from "react-icons/bs";
import InfoCard from '../../components/InfoCard/InfoCard';

function Soporte() {

    return (
        <>
        <Sidebar/>
        <div className='soporte'>
            <h2>Vieci checklists<span>.</span></h2>
            <div className="soporte__container">
                <Link to='/vieci-checklists-higiene-manos'>
                    <InfoCard
                        title={<BsClipboardCheck/>}
                        subtitle='Higiene de manos'
                    /> 
                </Link>
            </div>
        </div>
        </>
    )
}

export default Soporte
