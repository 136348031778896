import React, { useState } from 'react';
import './ListaAntibioticos.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import OptionBar from '../../components/OptionBar/OptionBar';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

function ListaAntibioticos() {

    const data = [
        {
            nombre: 'No hay Datos',
            concentracion: 'No hay datos',
            costo: '$No hay datos',
            caracteristicas: 'No hay datos',
            actions:<Link to='/perfil-antibiotico'><Button title='Ver' className='tb__btn--alt'/></Link>,
        },
        // {
        //     nombre: 'Amikacina Ampolla',
        //     concentracion: '500 mg',
        //     costo: '$1,172',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Ampicilina vial polvo INY',
        //     concentracion: '500 mg',
        //     costo: '$1,538',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Ampicilina vial polvo INY',
        //     concentracion: '1 G',
        //     costo: '$2,000',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Ampicilina + sulbactan polvo INY',
        //     concentracion: '1.5 G',
        //     costo: '$2,775',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Avelox (Moxifloxacino) ampolla',
        //     concentracion: '400 mg',
        //     costo: '$69,172',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Azitromicina tableta',
        //     concentracion: '500 mg',
        //     costo: '$615',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Cefalexina capsula',
        //     concentracion: '500 mg',
        //     costo: '$731',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Cefalexina ampolla',
        //     concentracion: '1 G',
        //     costo: '$2,500',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Cefazolina ampolla',
        //     concentracion: '1 G',
        //     costo: '$2,500',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Cefepime ampolla',
        //     concentracion: '1 G',
        //     costo: '$5,500',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Cefradina polvo para disolver',
        //     concentracion: '1 G',
        //     costo: '$3,202',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Ceftriaxona ampolla',
        //     concentracion: '1 G',
        //     costo: '$1,495',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Cipro (ciprofloxacino) amp.',
        //     concentracion: '400 mg',
        //     costo: '$66,090',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Ciprofloxacino ampolla',
        //     concentracion: '100 mg',
        //     costo: '$1,522',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Ciprofloxacino tableta',
        //     concentracion: '500 mg',
        //     costo: '$168',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Clavulin suspension',
        //     concentracion: '400 mg',
        //     costo: '$22,328',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Clavulin suspension',
        //     concentracion: '400 mg',
        //     costo: '$22,328',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Claritromicina tableta',
        //     concentracion: '500 mg',
        //     costo: '$17,753',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Clindamicina ampolla',
        //     concentracion: '600 mg',
        //     costo: '$1,520',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Cubicin (Daptomicina)',
        //     concentracion: '350 mg',
        //     costo: 'No hay datos',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Cubicin (Daptomicina)',
        //     concentracion: '500 mg',
        //     costo: 'No hay datos',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Ertapenen polvo esteril para INY',
        //     concentracion: '1 G',
        //     costo: '$72,573',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Gentamicina ampolla',
        //     concentracion: '80 mg',
        //     costo: '$1,206',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Gentamicina ampolla',
        //     concentracion: '160 mg',
        //     costo: '$1,286',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Ifaxim (Rifaximina) capsula',
        //     concentracion: '550 mg',
        //     costo: '$2,250',
        //     caracteristicas: 'Sin restricción',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Klaricid IV (Claritromicina)',
        //     concentracion: '500 mg',
        //     costo: '$18,372',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Klaricid suspensión',
        //     concentracion: '125 mg',
        //     costo: '$86,326',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Klaricid tableta (Claritromicina)',
        //     concentracion: '125 mg',
        //     costo: '$86,326',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Merobac amp. (Meropenem)',
        //     concentracion: '1 G',
        //     costo: '$17,400',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Meronem (Meropenem polvo para  dis)',
        //     concentracion: '1 G',
        //     costo: '$46,002',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Meropenem ampolla',
        //     concentracion: '1 G',
        //     costo: '$11,331',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Moxifloxacino gotas',
        //     concentracion: '5%',
        //     costo: '$22,200',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Oxacilina ampolla',
        //     concentracion: '1 g',
        //     costo: '$1,502',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Penicilina  5.000.000UI',
        //     concentracion: '5.000.000 UI',
        //     costo: '$1,683',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Penicilina Benzatinica  1.200.000UI',
        //     concentracion: '1.200.000 UI',
        //     costo: '$1,714',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Penicilina Benzatinica  2.400.000UI',
        //     concentracion: '1.200.000 UI',
        //     costo: '$1,714',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Penicilina G sódica polvo 1.000.000',
        //     concentracion: '1.000.000 UI',
        //     costo: '$1,286',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Piperacilina + tazobactam',
        //     concentracion: '4.5 G',
        //     costo: '$8,800',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Polimixina B 5.000.000 ampolla',
        //     concentracion: '500.000 UI',
        //     costo: '$94,288',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Rocefin (Ceftriaxona) amp.',
        //     concentracion: '1 G',
        //     costo: '$21,213',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Sulamp Suspensión',
        //     concentracion: '250 mg',
        //     costo: '$100,400',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Tapectam (Piperacilina + Tazobactam)',
        //     concentracion: '405 mg',
        //     costo: '$17,674',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Unasyn suspensión',
        //     concentracion: '250 mg/5 ml',
        //     costo: '$77,406',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Unasyn amp. (Ampi + Sulbact)',
        //     concentracion: '1.5 G',
        //     costo: '$10,339',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Vancomicina ampolla',
        //     concentracion: '500 mg',
        //     costo: '$5,608',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Linezolid inyect',
        //     concentracion: '600 mg',
        //     costo: '$39,189',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Linezolid inyect',
        //     concentracion: '500 mg',
        //     costo: '$19,864',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Trimetropr¡n tab.',
        //     concentracion: '160/800 mg',
        //     costo: '$477',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Trimetropr¡n/Sulfa ampolla',
        //     concentracion: '80/400 mg',
        //     costo: '$2,875',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Zinnat (Cefurozina) susp oral',
        //     concentracion: '250 mg/5 ML',
        //     costo: '$2,875',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
        // {
        //     nombre: 'Ceftazidima/avibactam',
        //     concentracion: '2,5 G',
        //     costo: '$404,061',
        //     caracteristicas: 'Restringido',
        //     actions: <Button title='Ver' className='tb__btn--alt'/>,
        // },
       
    ]

     const [search, setSearch] = useState('');

     const filterData = data.filter((data)=> {
        return data.nombre.toLowerCase().includes(search.toLowerCase())
    })

     const handleSearch = (e) => {
        setSearch(e.target.value)
     }
    return (
        <>
        <Sidebar/>
        <div className='antibioticos'>
            <h2>Antibióticos<span>.</span></h2>
            <OptionBar/>
            <div className="antibioticos__container">
                <h4>Listado de antibibióticos</h4>
                <div className="antibioticos__list">
                    <Input
                        type='text'
                        placeholder='Buscar antibiótico...'
                        value={search}
                        onChange={handleSearch}
                    />
                    <br />
                    <table >
                            <tr>
                                <th>Antibiótico</th>
                                <th>Concentración por unidad</th>
                                <th>Valor compra unidad</th>
                                <th>Restricción</th>
                                <th>Acciones</th>
                            </tr>
                                {
                                    filterData.map( item => 
                                        <tr>
                                            <td>{item.nombre}</td>
                                            <td>{item.concentracion}</td>
                                            <td>{item.costo}</td>
                                            <td>{item.caracteristicas}</td>
                                            <td className='tb__container'>{item.actions}</td>
                                        </tr>
                                        )
                                }
                      
                        </table>
                </div>
            </div>
        </div>
        </>
    )
}

export default ListaAntibioticos
