import React from 'react';
import './Dashboard.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import InfoCard from '../../components/InfoCard/InfoCard';
import GeneralFilter from '../../components/GeneralFilter/GeneralFilter';
import Button from '../../components/Button/Button';
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';

function Dashboard() {

    const data = [
        {
            key: '1',
            title: '26',
            subtitle: 'Total de cultivos positivos'
        },
        {
            key: '2',
            title: 'S. epidermidis',
            subtitle: 'Microorgismo mas prevalente'
        },
        {
            key: '3',
            title: 'Urocultivo - hemocultivo',
            subtitle: 'Tipo de cultivo mas tomado'
        },
        {
            key: '4',
            title: 'UCI neonatal',
            subtitle: 'Servicio con más cultivos positivos'
        },
    ]
    return (
        <>
        <Sidebar/>
        <div className='dashboard'>
            <h2>Dashboard<span>.</span></h2>
            <br />
            <div className="dashboard__info">
                {
                    data.map(item => 
                        <InfoCard
                            key={item.key}
                            title={item.title}
                            subtitle={item.subtitle}
                        />
                        )
                }
              
            </div>
            <div className="dashboard__graph-container">
                <div className='dashboard__graph-title'>
                <h5>Tasa de infección</h5>
                <div className="dashboard__graph">
                <Line
                        data={{
                            labels:['hola', 'jenny'],
                            datasets: [{
                                label: 'Tasa global de infección',
                                data: [],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                </div>
                </div>
                <div className="dashboard__graph-title">
                <h5>Microorganismos mas prevalentes</h5>
                    <div className="dashboard__graph">
                    <Pie
                        data={{
                            labels:['S. epidermis,', 'E. coli', 'C. albicans', 'E. faecalis', ' K. pneumoniae'],
                            datasets: [{
                                label: '',
                                data: [6,5,2,2,2],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Dashboard
