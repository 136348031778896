import React, { useState } from 'react';
import './Informes.scss';
import { Link } from 'react-router-dom';
import Input from '../../../components/Input/Input';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Docbar from '../../../components/Docbar/Docbar';
import Button from '../../../components/Button/Button';

function Informes() {

    const data = [
        {
            id: '1',
            type: 'Presentación',
            name: 'Consumo de antibióticos',
            code: '20/09/2022',
            link: <a href="https://drive.google.com/file/d/1-wLHyIoCdH_WAFi9S1Mj7z4P0fniTj0l/view?usp=sharing" target="_blank"><Button title='Descargar' className='tb__btn'/></a>
        },

    ]

    const [search, setSearch] = useState('');

    const filterData = data.filter((data)=> {
       return data.name.toLowerCase().includes(search.toLowerCase())
   })

    const handleSearch = (e) => {
       setSearch(e.target.value)
    }
     
    return (
        <>
        <Sidebar/>
        <div className='documentos'>
            <h2>Documentos<span>.</span></h2>
            <Docbar /> 
            <div className="documentos__container">
                <h3>Informes</h3>
                <table>
                        <tr>
                            <th><b>Categoría</b></th>
                            <th><b>Nombre del documento</b></th>
                            <th><b>Fecha de actualización</b></th>
                            <th><b>Acciones</b></th>
                        </tr>
                        {
                                    filterData.map( item => 
                                        <tr>
                                            <td>{item.type}</td>
                                            <td>{item.name}</td>
                                            <td>{item.code}</td>
                                            <td className='tb__container'>{item.link}</td>
                                        </tr>
                                        )
                                }
                    </table>
            </div>
        </div>
        </>
    )
}

export default Informes
