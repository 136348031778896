import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserAuthContextProvider } from '../providers/UserAuthContext';
import ProtectedRoute from '../providers/ProtectedRoute';
//components
import Login from '../pages/Login/Login';
import Dashboard from '../pages/Dashboard/Dashboard';
import Analytics from '../pages/Analytics/Analytics';
import IAAS from '../pages/Analytics/Analisis/IAAS';
import Checklists from '../pages/Checklists/Checklists';
import Trimestre from '../pages/Dashboard/Trimestre';
import SieteDias from '../pages/Dashboard/SieteDias';
import Quincena from '../pages/Dashboard/Quincena';
import Mes from '../pages/Dashboard/Mes';
import Semestre from '../pages/Dashboard/Semestre';
import Notification from '../pages/Notification/Notification';
import Calendario from '../pages/Calendario/Calendario';
import Evento from '../pages/Calendario/Evento/Evento';
import Informes from '../pages/Documents/Informes/Informes';
import Documents from '../pages/Documents/Documents';
import Actas from '../pages/Documents/Actas/Actas';
import Protocolos from '../pages/Documents/Protocolos/Protocolos';

//Upload
import SubirArchivos from '../pages/SubirArchivos/SubirArchivos';
import Analisis from '../pages/Analisis/Analisis';

//departamentos
import Epidemiologia from '../pages/Epidemiologia/Epidemiologia';
//analisis
import Microorganismos from '../pages/Microorganismos/Microorganismos';
  import Siete from '../pages/Microorganismos/Siete';
  import Quince from '../pages/Microorganismos/Quince';
  import CultivosMes from '../pages/Microorganismos/CultivosMes';
  import CultivoSemestre from '../pages/Microorganismos/CultivoSemestre';
import ListaAntibioticos from '../pages/Antibioticos/ListaAntibioticos';
  import Costos from '../pages/Antibioticos/Costos/Costos';
  //Perfil Antibiótico
  import Demo from '../pages/Antibioticos/Perfil/Demo';
import Servicios from '../pages/Servicios/Servicios';
  import Prioriatria from '../pages/Servicios/Prioritaria';
  import Cirugia from '../pages/Servicios/Cirugia';
  import Hospitalizacion from '../pages/Servicios/Hospitalizacion';
  import UciNeo from '../pages/Servicios/UcINeo';
import Listas from '../pages/Listas/Listas.jsx';
  import HigieneManos from '../pages/Listas/HigieneManos/HigieneManos';
  import Seguridad from '../pages/Listas/Seguridad/Seguridad';
//Admin
import Perfil from '../pages/Perfil/Perfil.jsx';
import CrearUsuario from '../pages/CrearUsuario/CrearUsuario';
import Soporte from '../pages/Soporte/Soporte';
import { zhCN } from 'date-fns/locale';


function App() {
  return (
  <UserAuthContextProvider>
    <Router>
      <Routes>
        <Route exact path='/' element={<Login/>}/>
        <Route exact path='/dashboard' element={ <ProtectedRoute><Dashboard/></ProtectedRoute>}/>
        <Route exact path='/vieci-analytics' element={ <ProtectedRoute><Analytics/></ProtectedRoute>}/>
          <Route exact path='/vieci-analytic-iaas' element={ <ProtectedRoute><IAAS/></ProtectedRoute>}/>
        <Route exact path='/vieci-checklists' element={ <ProtectedRoute><Checklists/></ProtectedRoute>}/>

        {/* Vision general */}
          <Route exact path='/ultima-semana' element={<ProtectedRoute><SieteDias/></ProtectedRoute>}/>
          <Route exact path='/ultima-quincena' element={ <ProtectedRoute><Quincena/></ProtectedRoute>}/>
          <Route exact path='/ultimo-mes' element={<ProtectedRoute><Mes/></ProtectedRoute>}/>
          <Route exact path='/ultimo-trimestre' element={<ProtectedRoute><Trimestre/></ProtectedRoute>}/>
          <Route exact path='/ultimo-semestre' element={<ProtectedRoute><Semestre/></ProtectedRoute>}/>
        <Route exact path='/notificaciones' element={<ProtectedRoute><Notification/></ProtectedRoute>}/>
        <Route exact path='/calendario' element={<ProtectedRoute><Calendario/></ProtectedRoute>}/>
        <Route exact path='/evento' element={<ProtectedRoute><Evento/></ProtectedRoute>}/>
        <Route exact path='/informes' element={<ProtectedRoute><Informes/></ProtectedRoute>}/>
        <Route exact path='/documentos-reportes' element={<ProtectedRoute><Documents/></ProtectedRoute>}/>
        <Route exact path='/documentos-protocolos' element={<ProtectedRoute><Protocolos/></ProtectedRoute>}/>
        <Route exact path='/documentos-informes' element={ <ProtectedRoute><Informes/></ProtectedRoute>}/>
        {/* Upload */}
        <Route exact path='/upload' element={<ProtectedRoute><SubirArchivos/></ProtectedRoute>}/>
        {/* Analisis */}
        <Route exact path='/epidemiologia' element={<ProtectedRoute><Epidemiologia/></ProtectedRoute>}/>
   
        <Route exact path='/upload' element={<ProtectedRoute><SubirArchivos/></ProtectedRoute>}/>
        <Route exact path='/analisis' element={<ProtectedRoute><Analisis/></ProtectedRoute>}/>
        <Route exact path='/microorganismos' element={<ProtectedRoute><Microorganismos/></ProtectedRoute>}/>
        <Route exact path='/microorganismos-siete-dias' element={<ProtectedRoute><Siete/></ProtectedRoute>}/>
        <Route exact path='/microorganismos-quince-dias' element={<ProtectedRoute><Quince/></ProtectedRoute>}/>
        <Route exact path='/microorganismos-mes' element={<ProtectedRoute><CultivosMes/></ProtectedRoute>}/>
        <Route exact path='/microorganismos-semestre' element={<ProtectedRoute><CultivoSemestre/></ProtectedRoute> }/>
        <Route exact path='/lista-antibioticos' element={<ProtectedRoute><ListaAntibioticos/></ProtectedRoute>}/>

          <Route exact path='/antibioticos-costos' element={<ProtectedRoute><Costos/></ProtectedRoute>}/>         
            {/* Perfil */}
            <Route exact path='/perfil-antibiotico' element={<ProtectedRoute><Demo/></ProtectedRoute>}/>
        <Route exact path='/servicios' element={<ProtectedRoute><Servicios/></ProtectedRoute>}/>
          <Route exact path='/servicios-prioritaria' element={<ProtectedRoute><Prioriatria/></ProtectedRoute>}/>
          <Route exact path='/servicios-cirugia' element={<ProtectedRoute><Cirugia/></ProtectedRoute>}/>
          <Route exact path='/servicios-hospitalizacion' element={<ProtectedRoute><Hospitalizacion/></ProtectedRoute>}/>
          <Route exact path='/servicios-uci-neonatal' element={<ProtectedRoute><UciNeo/></ProtectedRoute>}/>
        <Route exact path='/vieci-checklists-higiene-manos' element={<ProtectedRoute><Listas/></ProtectedRoute>}/>
          <Route exact path='/higiene-manos' element={<ProtectedRoute><HigieneManos/></ProtectedRoute>}/>
          <Route exact path='/seguridad' element={<ProtectedRoute><Seguridad/></ProtectedRoute>}/>
        <Route exact path='/perfil' element={<ProtectedRoute><Perfil/></ProtectedRoute> }/>
        <Route exact path='/crear-usuario' element={<ProtectedRoute><CrearUsuario/></ProtectedRoute>}/>
        <Route exact path='/centro-de-ayuda' element={<ProtectedRoute><Soporte/></ProtectedRoute>}/>
      </Routes>
    </Router>
  </UserAuthContextProvider>
  );
}

export default App;
