import React, { useState } from 'react';
import './GeneralFilter.scss';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';

function FilterPeriod() {

    const navigate = useNavigate();
    const [filter, setFilter] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        return navigate(filter)
    }

    return (
        <div className='filter-period'>
            {/* <small>Filtrar periodo</small> */}
            <form onSubmit={handleSubmit}>
                <div className="filter-period__container">
                    <select className='input-options' value={filter} onChange={(e)=>setFilter(e.target.value)}>
                            <option hidden defaultValue>Elige el periodo</option> 
                                {/* <option value='/dashboard'>Últimas 24 horas</option>
                                <option value='/ultima-semana'>Últimos 7 días</option>
                                <option value='/ultima-quincena'>Últimos 15 días</option> */}
                                <option value='/vieci-analytic-iaas'>Último mes</option>
                                <option value='/ultimo-trimestre'>Último trimestre</option>
                                <option value='/ultimo-semestre'>Último semestre</option>
                    </select>
                    <Button
                        title='Filtar'
                        className='filter-period__btn'
                    /> 
                </div>
            </form>
        </div>
    )
}

export default FilterPeriod
