import React, { useState } from 'react';
import './Evento.scss';
import { db } from '../../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import Swal from 'sweetalert2'

function Evento() {
    const data = [
        {
            key: '1',
            label: 'Nombre del evento',
            type: 'text',
            onChange: (e)=>setName(e.target.value)
        },
        {
            key: '2',
            label: 'Fecha de inicio',
            type: 'date',
            onChange: (e)=>setDate(e.target.value)
        },
        {
            key: '3',
            label: 'hora inicio',
            type: 'time',
            onChange: (e)=>setTime(e.target.value)
        },
        {
            key: '4',
            label: 'Fecha fin',
            type: 'date',
            onChange: (e)=>setEndDate(e.target.value)
        },
        {
            key: '5',
            label: 'Hora fin',
            type: 'time',
            onChange: (e)=>setEndTime(e.target.value)
        },
        {
            key: '6',
            label: 'Asistentes',
            type: 'text',
            onChange: (e)=>setEmail(e.target.value)
        },
    ]

    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    const [email, setEmail] = useState('');
    
    const formCollection = collection(db, 'Eventos');
    const navigate = useNavigate();

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        await addDoc(formCollection, {
            name: name,
            date: date,
            time: time,
            endDate: endDate,
            endTime: endTime,
            email: email,
        })
        .then(()=> {
            Swal.fire(
                '¡Exito!',
                'El evento ha sido creado exitosamente',
                'success'
             )
        })
        .then(()=> navigate('/calendario'))
    }
    
  return (
    <>
    <Sidebar/>
    <div className="evento">
        <h2>Agregar un evento.</h2>
        <div className="evento__container">
            <form onSubmit={handleSubmit}>
           {
            data.map(item => 
                <Input
                    key={item.key}
                    label={item.label} 
                    type={item.type}
                    text={item.text}
                    onChange={item.onChange}
                />  
            )
           }
           <div className="evento__btn">
                <Button
                    title='Agregar evento'
                />
           </div>
            </form>
        </div>

    </div>
    </>
  )
}

export default Evento