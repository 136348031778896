import React from 'react';
import './Analisis.scss';
import Sidebar from '../../../components/Sidebar/Sidebar';
import InfoCard from '../../../components/InfoCard/InfoCard';
import GeneralFilter from '../../../components/GeneralFilter/GeneralFilter';
import Button from '../../../components/Button/Button';
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';

function IAAS () {

    const data = [
        {
            key: '1',
            title: '26',
            subtitle: 'Total de cultivos positivos'
        },
        {
            key: '2',
            title: 'S. epidermidis',
            subtitle: 'Microorgismo mas prevalente'
        },
        {
            key: '3',
            title: 'Urocultivo - hemocultivo',
            subtitle: 'Tipo de cultivo mas tomado'
        },
        {
            key: '4',
            title: 'UCI neonatal',
            subtitle: 'Servicio con más cultivos positivos'
        },
    ]
    return (
        <>
        <Sidebar/>
        <div className='analisis'>
            <h2>Análisis de IAAS<span>.</span></h2>
            <br />
            <GeneralFilter/>
            <br />
            <h4>Último mes</h4>
            <div className="analisis__info">
                {
                    data.map(item => 
                        <InfoCard
                            key={item.key}
                            title={item.title}
                            subtitle={item.subtitle}
                        />
                        )
                }
              
            </div>
            <div className="analisis__graph-container">
                <div className='analisis__graph-title'>
                <h5>Tasa de infección</h5>
                <div className="analisis__graph">
                <Line
                        data={{
                            labels:['hola', 'jenny'],
                            datasets: [{
                                label: 'Tasa global de infección',
                                data: [],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                </div>
                </div>
                <div className="analisis__graph-title">
                <h5>Microorganismos mas prevalentes</h5>
                    <div className="analisis__graph">
                    <Pie
                        data={{
                            labels:['S. epidermis,', 'E. coli', 'C. albicans', 'E. faecalis', ' K. pneumoniae'],
                            datasets: [{
                                label: '',
                                data: [6,5,2,2,2],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                    </div>
                </div>
            </div>
            <div className="analisis__card-container">
                <div className='analisis__card-title'>
                <h5>Tipo de cultivo</h5>
                <div className="analisis__card">
                <Bar
                            data={{
                                labels:['Coprocultivo', 'Hemocultivo', 'Secreción', 'Urocultivo'],
                                datasets: [{
                                    label: 'Antibióticos usados en el primer semestre',
                                    data: [1, 12, 1, 12],
                                    backgroundColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                    borderColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"30%"}
                            options={{ maintainAspectRatio: false }}
                            height={600}
                        />
                </div>
                </div>
                <div className='analisis__card-title'>
                    <h5>Servicio con más cultivos positivos</h5>
                <div className="analisis__card">
                <Doughnut
                         data={{
                            labels:['S. epidermis,', 'C. albicans', 'C. parapsilosis','E. coli' , 'E. faecalis'],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [6,2,2,1,1],
                                backgroundColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                borderColor: [
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
                <div className='analisis__card-title'>
                    <h5>Cultivos por servicio</h5>
                <div className="analisis__card">
                <Bar
                         data={{
                            labels:['Hospitalización', 'UCI Neonatal', 'UCI Adultos', 'Cirugia', 'C. externa'],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [7, 17, 1, 0, 1],
                                backgroundColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                     
                                    ],
                                borderColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                      
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
            
            </div>
        </div>
        </>
    )
}

export default IAAS;
