import React from 'react';
import './Listas.scss';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import GeneralFilter from '../../components/GeneralFilter/GeneralFilter';
import ListasBar from '../../components/ListasBar/ListasBar';
import InfoCard from '../../components/InfoCard/InfoCard';
import { Pie, Doughnut, Line, Bar } from 'react-chartjs-2';


function Listas() { 
    
    const data = [
        {
            key: '1',
            title: '82%',
            subtitle: 'Adherencia total'
        },
        {
            key: '2',
            title: 'UCI Adultos',
            subtitle: 'Servicio con mayor adherencia'
        },
        {
            key: '3',
            title: 'Enfermera(o)',
            subtitle: 'Cargo con mayor adherencia'
        },
        {
            key: '4',
            title: '63',
            subtitle: 'Total formularios subidos'
        },
    ]

    return (
        <>
        <Sidebar/>
        <div className='listas'>
            <h2>Estrategia multimodal</h2>
       
            <h2>Higiene de manos</h2>
            <br />
            <h4>Semana 10 oct - 23 oct</h4>
            <div className="listas__info">
                {
                    data.map(item => 
                        <InfoCard
                            key={item.key}
                            title={item.title}
                            subtitle={item.subtitle}
                        />
                        )
                }
              
            </div>
            <div className="listas__graph-container">
                <div className='listas__graph-title'>
                <h5>Total formularios subidos</h5>
                <div className="listas__graph">
                <Line
                        data={{
                            labels:['10 oct', '11 oct', '12 oct', '13 oct', '14 oct', '15 oct', '16 oct', '17 oct', '18 oct', '19 oct','20 oct', '21 oct', '22 oct', '23 oct'],
                            datasets: [{
                                label: '# Formularios subidos',
                                data: [0, 15, 20, 5, 7, 0, 0, 6, 0, 12, 6, 7, 4, 9],
                                backgroundColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    />
                </div>
                </div>
                <div className="listas__graph-title">
                <h5>Aherencia por servicio</h5>
                    <div className="listas__graph">
                    <Bar
                        data={{
                            labels:["Hospitalización", "UCI Adultos", "Consulta externa", "UCI neonatal", "Cirugia"],
                            datasets: [{
                                label: 'Adherencia por servicio',
                                data: [82, 94, 0, 85, 55, 82],
                                backgroundColor: [
                                    '#F5E87C',
                                    '#76F579 ',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F57F79',
                                    '#F5E87C',
                                ],
                                borderColor: [
                                    '#6C89F5',
                                    '#72F5E8',
                                    '#76F579 ',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F57F79',
                                ],
                            }
                            ],
                            
                        }}
                        width={"10%"}
                        options={{ maintainAspectRatio: false }}
                        height={2000}

                    /> 
                    </div>
                </div>
            </div>
            <div className="listas__card-container">
                <div className='listas__card-title'>
                <h5>Adherencia por momento por servicio</h5>
                <div className="listas__card">
                <Line
                            data={{
                                labels:['Momento 1', 'Momento 2', 'Momento 3', 'Momento 4', 'Momento 5'],
                                datasets: [{
                                    label: 'Porcentaje de adherencia por momento por servicio',
                                    data: [79, 85, 83, 88, 74, 0],
                                    backgroundColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                    borderColor: [
                                        '#72F5E8',
                                        '#F5E87C',
                                        '#76F579 ',
                                        '#F5AB5C',
                                        '#6C89F5',
                                        '#F57F79',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"30%"}
                            options={{ maintainAspectRatio: false }}
                            height={600}
                        />
                </div>
                </div>
                <div className='listas__card-title'>
                    <h5>Adherencia por cargo</h5>
                <div className="listas__card">
                <Bar
                         data={{
                            labels:["Auxuliar", "Médico especialista", "Enfermera(o)", "Otras profesiones", "Estudiante", "Médico general"],
                            datasets: [{
                                label: 'Porcentaje de adherencia por cargo',
                                data: [84, 59, 99, 90, 87, 70, 82],
                                backgroundColor: [
                                        '#F5E87C',
                                        '#F57F79',
                                        '#76F579',
                                        '#76F579',
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F5E87C',
                                    ],
                                borderColor: [
                                    '#F5E87C',
                                    '#F57F79',
                                    '#76F579',
                                    '#76F579',
                                    '#F5E87C',
                                    '#F5AB5C',
                                    '#F5E87C',
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
                <div className='listas__card-title'>
                    <h5>Adherencia por momento por cargo</h5>
                <div className="listas__card">
                <Line
                         data={{
                            labels:['Momento 1', 'Momento 2', 'Momento 3', 'Momento 4', 'Momento 5'],
                            datasets: [{
                                label: 'Servicio con mas infecciones',
                                data: [79, 85, 83, 88, 74, 0],
                                backgroundColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                     
                                    ],
                                borderColor: [
                                        '#F5E87C',
                                        '#F5AB5C',
                                        '#F57F79',
                                        '#6C89F5',
                                        '#72F5E8',
                                        '#76F579 ',
                                      
                                    ],
                                }
                                ],
                                
                            }}
                            width={"10%"}
                            options={{ maintainAspectRatio: false }}
                            height={2000}
                    /> 
                </div>
                </div>
            
            </div>
        </div>
        </>
    )
}

export default Listas
